<template>
  <div class="comm-page">
    <!--rate_page_head start-->
    <div class="rate_page_head flex flex-between">
      <div class="rate_nav flex">
        <div class="li">
          <router-link  to="/personage/honor">晋升</router-link>
        </div>
        <div class="li"><router-link class="active" to="/personage/honorAward">获奖</router-link> </div>
      </div>
      <el-button class="ind-add-btn-o" @click="$refs.pop.open()">奖励</el-button>
    </div>
    <!--rate_page_head end-->
    
    
    <!--ind-main end-->
  </div>
</template>

<script>

// import 'swiper/css/swiper.css'
export default {
  components: {},

  data() {
    return {

      page: 1, //页数
      total: 0, //条数
      list: [], //列表
    };
  },
};

</script>


<style scoped lang="less">
.comm-page{}
.rate_page_head{
  background:#fff;padding:0 40px;align-items:center;height:64px;border-bottom:1px solid #ebebeb;
  .rate_nav{
    .li{font-size:18px;color:#666;margin-right:55px;cursor:pointer;position:relative;line-height:64px;}
    .active{color:#3273F6;}
    .active:after{width:24px;height:2px;background:#3273F6;position:absolute;left:50%;bottom:0;margin-left:-12px;display:block;content:"";}
  }
}
.award-hd-img img{display:block;max-width:100%;margin:0 auto;width:565px;}
.honor-head{
  display:flex;justify-content:space-between;padding:10px 40px;background:#fff;margin-bottom:10px;align-items:center;
  .head-nav{display:flex;}
  .li{margin-right:55px;line-height:36px;}
  .nav-link{font-size:16px;color:#666;line-height:36px;}
  .active{color:#3273F6;}

}

.ind-add-btn-o{width:88px;height:36px;border-radius:4px;background:#FFA740;padding:0px;text-align:center;line-height:36px;font-size:16px;color:#fff;border:none;}
.ind-main{
  background:#fff;padding:40px;
  .ind-bd{
    position:relative;margin-top:40px;
    .bd-line{position:absolute;left:0px;top:0px;width:2px;background:#7BA6FE;height:100%;}
    .bd-item{
      background:#F4F8FF;border-radius:4px;padding:10px 30px;position:relative;margin:20px 0px 20px 30px;
      display:flex;justify-content:space-between;
      .circle{
        width:22px;height:22px;border-radius:100%;position:absolute;left:-40px;top:50%;margin-top:-6px;border:1px solid #7BA6FE;display:flex;align-items:center;justify-content:center;background:#fff;
        .ii{width:12px;height:12px;border-radius:100%;background:#7BA6FE;}
      }
      .d{width:150px;text-align:right;font-size:14px;line-height:24px;}
      .t{width:calc(100% - 150px);font-size:16px;color:#666;line-height:24px;}
    }
  }

}
</style>